@import url("../global.css");

:root {
  --divheight: 40rem;
  --divwidth: 100%;
}

.main {
  background-image: url("../../../images/bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: var(--divheight);
  width: var(--divwidth);
}

.maincontent {
  background: rgba(0, 0, 0, 0.3);
  height: var(--divheight);
  width: var(--divwidth);
}
