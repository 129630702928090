:root{
    --margin-body: 2rem 0rem 0rem 0rem;
}

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

.gear-icon {
  animation: gear-spin infinite 10s linear;
}

@keyframes gear-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.maintainance p{
    color: rgba(20,29,231,100%);
    font-family:inherit;
    font-size: 2.5rem;
    font-weight: 700;
    margin: var(--margin-body);

}

.maintainance .gear-icon{
    margin: var(--margin-body);
}


